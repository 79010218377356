import request from 'api/requestUtils'
import { templates } from '@luxuryescapes/lib-uri-templates'
import offerPropertyMap from './mappers/offerPropertyMap'

function timezoneOffset() {
  return new Date().getTimezoneOffset() / 60 * -1
}

export function getProperty(propertyId: string) {
  return request.get<App.ApiResponse<any>>(templates.reservation.property.expand({
    id: propertyId,
  }))
    .then(data => offerPropertyMap(data.result))
}

function tourDateMap(date): App.TourDate {
  return {
    startDate: date.start_date,
    endDate: date.end_date,
    monthGroup: date.month_group,
    totalAvailable: date.total_available,
    guaranteedDeparture: date.guaranteedDeparture,
  }
}

export function tourEnquiry(
  tourId: string,
  tourOptionId: string,
  numberOfDays: number,
  noOfAdults: number,
) {
  return request.get<App.ApiResponse<Array<any>>>(templates.reservation.tour_enquiry.expand({
    tour_id: tourId,
    id: tourOptionId,
    days: numberOfDays,
    timezone_offset: timezoneOffset(),
    no_of_adults: noOfAdults,
  }))
    .then(data => data.result.map(tourDateMap))
}
